import React, { useState } from 'react';
import { Box, CircularProgress } from '@mui/material';
import AddButton from '../../../components/Admin/AddButton';
import AddTask from '../../../components/Admin/Tasks/AddTask';
import ModalWidget from '../../../components/ModalWidget/ModalWidget';
import AdminHeader from '../../../components/Admin/AdminHeader';
import { useGetTaskTemplatesQuery } from '../../../services/tasksSlice/tasksSlice';

import TaskCard from '../../../components/Admin/Tasks/TaskCard';
import {
  TaskPhase,
  TaskTemplate,
  TaskStatus,
} from '../../../../shared/types/Task';

const initTaskTemplate: TaskTemplate = {
  id: 0,
  createdAt: new Date().toString(),
  updatedAt: new Date().toString(),
  deletedAt: null,
  startedAt: null,
  completedAt: null,
  createdBy: new Date().toString(),
  taskTemplateID: 0,
  defaultAssignee: 'requestor',
  assignedUserID: '',
  defaultReviewerID: null,
  template: true,
  departmentID: 0,
  sepID: 0,
  phase: TaskPhase.initiate,
  status: TaskStatus.pending,
  enabled: false,
  review: true,
  locked: false,
  name: '',
  shortName: '',
  description: '',
  taskDataFields: [],
  parentTasks: [],
  stakeholderSummary:'',
  requestorSummary:'',
  groupName: '',
  groupId: null,
  taskAge: null
};

const AdminTasks = () => {
  const { data: TaskTemplates, isLoading } = useGetTaskTemplatesQuery();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [selectedTaskTemplate, setSelectedTaskTemplate] =
    useState(initTaskTemplate);

  return (
    <Box sx={{ minHeight: '500px' }}>
      <AdminHeader title="Tasks" />
      <ModalWidget
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        title={`${selectedTaskTemplate.name === '' ? 'Add' : 'Edit'} Tasks`}
        children={
          <AddTask
            onClose={() => setIsOpen(false)}
            selectedTask={selectedTaskTemplate}
          />
        }
      />
      {isLoading ? (
        <Box pt={10} sx={{ display: 'flex', justifyContent: 'center' }}>
          <CircularProgress data-testid="loading-spinner" size={100} />
        </Box>
      ) : (
        TaskTemplates?.map(
          (taskTemplate: TaskTemplate, key: number, tasks: TaskTemplate[]) => {
            return (
              <TaskCard
                taskTemplate={taskTemplate}
                key={key}
                tasks={tasks}
                onEditTriggered={(e: TaskTemplate) => {
                  setSelectedTaskTemplate(e);
                  setIsOpen(true);
                }}
              />
            );
          }
        )
      )}

      {!isLoading && (
        <AddButton
          tab="Tasks"
          onAdd={(tab: string) => {
            setSelectedTaskTemplate({
              ...initTaskTemplate,
            });
            setIsOpen(true);
          }}
        />
      )}
    </Box>
  );
};

export default AdminTasks;

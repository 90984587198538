import { DataFieldWithOptionsAndSingleLocation } from './DataField';
import { Department } from './Department';
import { SequelizeTimestamps } from './Sequelize';
import { UserShort } from './User';

export enum TaskStatus {
  pending = 'pending',
  todo = 'todo',
  inReview = 'inReview',
  changesRequested = 'changesRequested',
  complete = 'complete',
  cancelled = 'cancelled'
}
export enum ReadableTaskStatus {
  pending = 'Pending',
  todo = 'To-do',
  inReview = 'In Review',
  changesRequested = 'Changes Requested',
  complete = 'Complete',
  cancelled = 'Cancelled'
}
// export enum TaskStatus {
//   pending = 'pending',
//   todo = 'todo',
//   inReview = 'inReview',
//   changesRequested = 'changesRequested',
//   complete = 'complete',
//   inProcess = 'inProcess',
//   approved = 'approved',
//   cancelled = 'cancelled',
//   rejected = 'rejected',
//   expired = 'expired',
// }
// export enum ReadableTaskStatus {
//   pending = 'Pending',
//   todo = 'To-do',
//   inReview = 'In Review',
//   changesRequested = 'Changes Requested',
//   complete = 'Complete',
//   inProcess = 'In Process',
//   approved = 'Approved',
//   cancelled = 'Cancelled',
//   rejected = 'Rejected',
//   expired = 'Expired',
// }

export enum ValidTaskDependencyStatus {
  inReview = 'inReview',
  complete = 'complete',
}

export enum ValidTaskStatusUpdate {
  todo = 'todo',
  inReview = 'inReview',
  changesRequested = 'changesRequested',
  complete = 'complete',
}

export enum TaskPhase {
  initiate = 'initiate',
  design = 'design',
  implement = 'implement',
}

export enum ReadableTaskPhase {
  initiate = 'Initiate',
  design = 'Design',
  implement = 'Implement',
}

export interface Task extends SequelizeTimestamps {
  id: number;
  createdBy: string;
  status: TaskStatus;
  name: string;
  shortName: string;
  description?: string;
  sepID: number;
  departmentID: number;
  defaultReviewerID?: string | null;
  defaultAssignee?: string | null;
  review: boolean;
  enabled: boolean;
  template: boolean;
  locked: boolean;
  assignedUserID?: string;
  taskTemplateID?: number;
  phase: TaskPhase;
  startedAt: string | null;
  completedAt: string | null;
  stakeholderSummary:string | null;
  requestorSummary:string | null;
  groupName?: string | null;
  groupId?: number | null;
  taskAge?: number | null;
}

export interface TaskExtended extends Task {
  assignee: UserShort;
  defaultReviewer: UserShort | null;
  department: Department;
  parentTasks: Task[];
}

//task [taskID] must be at least in status [status] for task [dependentTaskID] to start
export interface TaskDependency extends SequelizeTimestamps {
  id: number;
  taskID: number;
  dependentTaskID: number;
  taskDependencyTemplateID?: number;
  status: ValidTaskDependencyStatus;
  createdBy: string;
  sepID: number;
  template: boolean;
}

export interface CreateTaskDependencyTemplateBody {
  taskID: number;
  dependentTaskID: number;
  status: ValidTaskDependencyStatus;
}
export interface UpdateTaskDependencyTemplateBody {
  taskID: number;
  dependentTaskID: number;
  status: ValidTaskDependencyStatus;
}

export interface TaskSearchRow {
  id: number;
  createdAt: string;
  updatedAt: string;
  name: string;
  startedAt: string | null;
  completedAt: string | null;
  phase: TaskPhase;
  status: TaskStatus;
  departmentID: number;
  sep: {
    id: number;
    name: string;
    phase: TaskStatus;
  };
  dependentTaskCount: number;
  assignee: UserShort;
  defaultReviewer: UserShort;
  department: Department;
}

export interface TaskSearchResult {
  count: number;
  tasks: TaskSearchRow[];
}

export interface UpdateTaskStatusBody {
  status: TaskStatus;
}

export interface UpdateTaskStatusMutationBody {
  taskID: number;
  status: TaskStatus;
}

export interface UpdateTaskStatusResult {
  code: number;
  description: string;
}

export interface UpdateTaskBody {
  enabled?: boolean;
  review?: boolean;
  name?: string;
  shortName?: string;
  description?: string;
  assignedUserID?: string;
  defaultReviewerID?: string | null;
  phase?: TaskPhase;
  defaultAssignee?: string | null;
  groupName?: string | null;
  groupId?: number | null;
  taskAge?: number | null;
}
export interface UpdateMultipleTaskBody {
  id: number;
  enabled?: boolean;
  review?: boolean;
  name?: string;
  shortName?: string;
  description?: string;
  assignedUserID?: string;
  defaultReviewerID?: string | null;
  phase?: TaskPhase;
  groupName?: string | null;
  sepID?: number;
}

export interface CreateTaskBody {
  sepID: number;
  name: string;
  shortName: string;
  phase: TaskPhase;
  template?: boolean;
  departmentID: number;
  enabled?: boolean;
  review?: boolean;
  description?: string;
  defaultAssignee: string;
  dependentTaskID?: number;
  groupName?: string | null;
  groupId?: number | null;
  taskAge?: number | null;
}

export interface TaskWithTaskDependency extends Task {
  taskDependency: TaskDependency;
}
export interface TaskTemplate extends Task {
  template: true;
  parentTasks: TaskWithTaskDependency[];
  taskDataFields: DataFieldWithOptionsAndSingleLocation[];
}

export interface CreateTaskTemplateBody {
  name: string;
  shortName: string;
  description?: string;
  departmentID: number;
  defaultAssignee: string | null;
  review: boolean;
  phase: TaskPhase;
  stakeholderSummary: string | null;
  requestorSummary: string | null;
  groupName?: string | null;
  groupId?: number | null;
  taskAge?: number | null;
}
export interface UpdateTaskTemplateBody {
  name?: string;
  shortName?: string;
  description?: string;
  departmentID?: number;
  defaultAssignee?: string | null;
  review?: boolean;
  phase?: TaskPhase;
  stakeholderSummary: string | null;
  requestorSummary: string | null;
  groupName?: string | null;
  groupId?: number | null;
  taskAge?: number | null;
}

import { sepAPI } from '../API';
import {
  CreateSEPBody,
  GetSEPResponse,
  SEP,
  SEPSearchResult,
  SearchParams,
  UpdateSEPBody,
} from '../../../shared/types/SEP';
import { setSnackbarForEndpoint } from '../../utils/snackbar';
import { TaskExtended } from '../../../shared/types/Task';
import { UserShort } from '../../../shared/types/User';

export const sepSlice = sepAPI.injectEndpoints({
  endpoints: (builder) => ({
    getSep: builder.query<GetSEPResponse, number>({
      query: (id) => `sep/${id}`,
      providesTags: ['SEPs'],
      onQueryStarted: (arg, api) => {
        setSnackbarForEndpoint(api, {
          errorMessage: 'There was a problem getting this SEP.',
        });
      },
    }),
    findSeps: builder.query<SEPSearchResult, SearchParams>({
      query: (arg) => {
        const {
          limit,
          offset,
          sortBy,
          sortAsc,
          phase,
          search,
          mine,
          myToDos,
          status,
          workFlowType
        } = arg;
        return {
          url: 'seps',
          params: {
            limit,
            offset,
            sortBy,
            sortAsc,
            phase,
            search,
            mine,
            myToDos,
            status,
            workFlowType
          },
        };
      },
      providesTags: ['SEPs'],
      onQueryStarted: (arg, api) => {
        setSnackbarForEndpoint(api, {
          errorMessage: 'There was a problem getting the list of SEPs.',
        });
      },
    }),
    createSep: builder.mutation<SEP, Partial<CreateSEPBody>>({
      query: ({ ...body }) => {
        return {
          url: `sep`,
          method: 'POST',
          body,
        };
      },
      invalidatesTags: ['SEPs', 'Tasks'],
      onQueryStarted: (arg, api) => {
        setSnackbarForEndpoint(api, {
          successMessage: 'SEP created successfully!',
          errorMessage: 'There was a problem creating your SEP.',
        });
      },
    }),
    partialSep: builder.query<SEPSearchResult, string>({
      query: (loggedInUserID) => ({
        url: `seps?creator.id=${loggedInUserID}&phase=knockout`,
        method: 'GET',
        providesTags: ['SEPs'],
      }),
    }),
    updateSep: builder.mutation<SEP, { id: number; body: UpdateSEPBody }>({
      query: ({ id, body }) => ({
        url: `sep/${id}`,
        method: 'PATCH',
        body,
      }),
      invalidatesTags: ['SEPs', 'TasksForSEP', 'Tasks'],
      onQueryStarted(arg, api) {
        setSnackbarForEndpoint(api, {
          successMessage: 'SEP updated successfully!',
          errorMessage: 'There was a problem updating your SEP.',
        });
      },
    }),
    updateSepRequestor: builder.mutation<SEP, { id: number; body: UserShort }>({
      query: ({ id, body }) => ({
        url: `sep/${id}/requestor`,
        method: 'PATCH',
        body,
      }),
      invalidatesTags: ['SEPs', 'TasksForSEP', 'Tasks'],
      onQueryStarted(arg, api) {
        setSnackbarForEndpoint(api, {
          successMessage: 'SEP updated successfully!',
          errorMessage: 'There was a problem updating your SEP.',
        });
      },
    }),
    getSepTasks: builder.query<TaskExtended[], number>({
      query: (id) => `sep/${id}/tasks?includeDisabled=true`,
      providesTags: ['TasksForSEP'],
    }),
    updateSepStatus: builder.mutation<
      SEP,
      { sepID: number; status: string; reason: string }
    >({
      query: ({ sepID, status, reason }) => ({
        url: `sep/update-status`,
        method: 'PATCH',
        body: { sepID, status, reason },
      }),
      invalidatesTags: ['SEPs'],
      onQueryStarted(arg, api) {
        setSnackbarForEndpoint(api, {
          successMessage: 'SEP updated successfully!',
          errorMessage: 'There was a problem updating your SEP.',
        });
      },
    }),
    updateKnockoutCancelSepStatus: builder.mutation<
      SEP,
      { sepID: number; status: string }
    >({
      query: ({ sepID, status }) => ({
        url: `sep/update-cancel-status`,
        method: 'PATCH',
        body: { sepID, status },
      }),
      invalidatesTags: ['SEPs'],
      onQueryStarted(arg, api) {
        setSnackbarForEndpoint(api, {
          successMessage: 'SEP updated successfully!',
          errorMessage: 'There was a problem updating your SEP.',
        });
      },
    }),
    getSubscribedSeps:builder.query<SEP, string>({
      query: (loggedInUserID) => ({
        url: `subscribed-sep?userEmail=${loggedInUserID}`,
        method: 'GET',
        providesTags: ['SEPs'],
      }),
    }),
    updateSepSubscriptions: builder.mutation<SEP, { id: number; body: UpdateSEPBody }>({
      query: ({ id, body }) => ({
        url: `sep-subscription/${id}`,
        method: 'PATCH',
        body,
      }),
      invalidatesTags: ['SEPs', 'TasksForSEP', 'Tasks'],
      onQueryStarted(arg, api) {
        setSnackbarForEndpoint(api, {
          successMessage: 'SEP updated successfully!',
          errorMessage: 'There was a problem updating your SEP.',
        });
      },
    }),
  }),
});

export const {
  useGetSepQuery,
  useFindSepsQuery,
  useCreateSepMutation,
  usePartialSepQuery,
  useUpdateSepMutation,
  useGetSepTasksQuery,
  useUpdateSepRequestorMutation,
  useUpdateSepStatusMutation,
  useUpdateKnockoutCancelSepStatusMutation,
  useGetSubscribedSepsQuery,
  useUpdateSepSubscriptionsMutation,
} = sepSlice;

import React, { useState } from "react";
import { useNavigate } from "react-router";
import { Divider, Button, Typography, Box, TextField } from "@mui/material";
import { useUpdateKnockoutCancelSepStatusMutation } from "../../services/sepSlice";

const CancelKnockoutSEP = ({
  sepId,
  setIsSEPCancelModalOpen,
}: {
  sepId: number;
  setIsSEPCancelModalOpen: any;
}) => {
//   const [cancelReason, setCancelReason] = useState("");
  const [updateKnockoutCancelSepStatus] = useUpdateKnockoutCancelSepStatusMutation();

  const navigate = useNavigate();

//   console.log(cancelReason, "cancelReason");
  const handleCancelSEP = async() => {
    console.log("cancelled triggered");
    await updateKnockoutCancelSepStatus({
      sepID: sepId,
      status: "cancelled"
    });
    setIsSEPCancelModalOpen(false);

    navigate("/");
  };
  return (
    <>
      <Box
        px={4}
        py={4}
        sx={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          gap: "20px",
        }}
      >
        <Typography>
          Do really want to cancel this SEP <b>{sepId}</b> ?
        </Typography>
        {/* <TextField
          multiline
          label={`Describe the reason for cancelling the ${sepId} SEP`}
          minRows={3}
          onChange={(e) => setCancelReason(e.target.value)}
          value={cancelReason}
        /> */}
      </Box>
      <Divider />
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          margin: "10px",
        }}
      >
    <Button size="large" onClick={()=>setIsSEPCancelModalOpen(false)} >
          No
        </Button>
        <Button size="large" variant="contained" onClick={handleCancelSEP} >
          Yes
        </Button>
      </Box>
    </>
  );
};

export default CancelKnockoutSEP;

import React, { useState, createContext, useEffect } from 'react';
import {
  Box,
  Divider,
  Button,
  Typography,
  CircularProgress,
} from '@mui/material';
import StepperDots from '../StepperDots/StepperDots';
import FirstStep from './FirstStep';
import SecondStep from './SecondStep';
import { CreateTaskBody, TaskExtended } from '../../../shared/types/Task';
import { useCreateTaskMutation } from '../../services/tasksSlice/tasksSlice';
import { useGetUserQuery } from '../../services/usersSlice';

const initialBody = {
  sepID: 0,
  name: '',
  shortName: '',
  phase: '',
  departmentID: '0',
  enabled: true,
  review: false,
  description: '',
  assignedUserID: '',
  defaultAssignee: '',
  assignedUserName: '',
  defaultReviewerID: '',
  label: '',
  pending: '',
  groupName: '',
  groupId: null,
  taskAge: null
};

export const TaskContext = createContext({
  task: initialBody,
  setTask: (data: any) => data,
});

const StepPage = ({
  isInit,
  sepID,
  departmentID,
  onFinish,
}: {
  isInit: boolean;
  sepID: number;
  departmentID?: number;
  onFinish: (newTask: TaskExtended) => void;
}) => {
  const [task, setTask] = useState<any>({
    ...initialBody,
    departmentID: departmentID === undefined ? 0 : departmentID,
    sepID,
  });
  const [indexOfCurrentScreen, setIndexOfCurrentScreen] = useState<number>(0);
  const [createTaskMutation, { isLoading }] = useCreateTaskMutation();
  const isFirstStepEnable =
    task.name !== '' && task.description !== '' && task.shortName !== '';
  const isSecondStepEnable = 
    (task.defaultAssignee === 'requestor' &&
      task.phase !== '' &&
      task.departmentID !== 0) ||
    (task.defaultAssignee === 'stakeholder' &&
      task.departmentID !== 0 &&
      task.phase !== '') ||
      (task.defaultAssignee.length >11 &&
      task.departmentID !== 0 &&
      task.phase !== '')||
    (task.review && task.departmentID !== 0 && task.phase !== '');
    const { data: userADInfo } = useGetUserQuery(task.defaultAssignee as string  | undefined, {
      skip: !task.defaultAssignee || task.defaultAssignee == 'requestor' || task.defaultAssignee == 'stakeholder' || task.defaultAssignee == 'Other',
    });

  useEffect(() => {
    if (isInit)
      setTask({
        ...initialBody,
        departmentID: departmentID === undefined ? 0 : departmentID,
        sepID,
      });
    setIndexOfCurrentScreen(0);
  }, [isInit, departmentID, sepID]);

  const handleSubmit = (clock: number) => {
    setIndexOfCurrentScreen(indexOfCurrentScreen + clock);
  };

 

  const handleCreateTask = () => {
    const requestBody: CreateTaskBody = {
      sepID: task.sepID,
      name: task.name,
      shortName: task.shortName,
      phase: task.phase,
      departmentID: task.departmentID,
      enabled: task.enabled,
      review: task.review,
      description: task.description,
      defaultAssignee: task.defaultAssignee,
      dependentTaskID: task.dependentTaskID,
    };
    console.log("defaultAssignee here ----",requestBody.defaultAssignee)
    createTaskMutation(requestBody as CreateTaskBody).then((res: any) => {
      if ('data' in res) onFinish(res.data as TaskExtended);
      setIndexOfCurrentScreen(0);
    });
  };

  return (
    <TaskContext.Provider value={{ task, setTask }}>
      <Box sx={{ diplay: 'flex', minWidth: '100%' }}>
        {indexOfCurrentScreen === 0 && <FirstStep />}
        {indexOfCurrentScreen === 1 && <SecondStep sepID={sepID}
        displayName = {(task?.defaultAssignee && task?.defaultAssignee?.length > 11) ? userADInfo?.displayName : null} />}
        <StepperDots steps={2} activeStep={indexOfCurrentScreen} />
      </Box>
      <Divider sx={{ backgroundColor: '#7E8083' }} />
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'right',
          p: 2,
          '& > button': {
            m: 1,
          },
        }}
      >
        {/** BACK BUTTON */}
        {indexOfCurrentScreen !== 0 && (
          <Button
            variant="text"
            disabled={false}
            onClick={() => handleSubmit(-1)}
            sx={{ textTransform: 'none' }}
          >
            <Typography
              sx={{
                font: 'normal normal 600 16px/22px Open Sans',
                color: '#2372B9',
              }}
            >
              <i className="fa-regular fa-angle-left"></i> &nbsp;Back
            </Typography>
          </Button>
        )}
        {/**CONTINUE BUTTON */}
        <Button
          variant="contained"
          onClick={() => {
            indexOfCurrentScreen === 0 ? handleSubmit(1) : handleCreateTask();
          }}
          disabled={
            indexOfCurrentScreen === 0
              ? !isFirstStepEnable
              : !isSecondStepEnable
          }
          sx={{
            textTransform: 'none',
            backgroundColor: '#2372B9',
            font: 'normal normal 600 16px/22px Open Sans',
          }}
        >
          {indexOfCurrentScreen === 0 ? 'Continue' : 'Add Task'}
        </Button>
        {isLoading && <CircularProgress sx={{ ml: 2 }} />}
      </Box>
    </TaskContext.Provider>
  );
};

export default StepPage;

import React, {useState, useEffect} from 'react';
import { Box, TextField, Stack, InputLabel, Typography } from '@mui/material';

import RichTextEditor from '../../../RichTextEditor/RichTextEditor';
import {
  CreateTaskTemplateBody,
  TaskTemplate,
} from '../../../../../shared/types/Task';

const textFieldSx = {
  color: '#222222',
  '& > p': {
    margin: '5px 0px',
    color: '#646669',
    fontSize: '11px',
  },
};

interface FirstStepProps {
  task: CreateTaskTemplateBody | TaskTemplate;
  description: string;
  setDescription: React.Dispatch<React.SetStateAction<string>>;
  handleChange: (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
}

const FirstStep = ({
  task,
  handleChange,
  description,
  setDescription,
}: FirstStepProps) => {
  return (
    <Box>
      <Stack
        display="flex"
        direction="column"
        alignItems="left"
        spacing={4}
        sx={{
          p: 4,
        }}
      >
        <Box>
          <InputLabel
            sx={{
              color: '#222222',
            }}
            htmlFor="task-name"
          >
            Task Name
          </InputLabel>
          <TextField
            sx={textFieldSx}
            size="small"
            id="name"
            name="name"
            fullWidth
            data-testid="task-name"
            value={task.name || ''}
            placeholder="Ex. Please have vendor complete the Cloud Security Requirements Mat..."
            helperText="This should be the actual task name that will display on an SEP's Task List."
            onChange={(e) => handleChange(e)}
          />
        </Box>
        <Box>
          <InputLabel
            sx={{
              color: '#222222',
            }}
            htmlFor="task-name"
          >
            Task Assigment Name
          </InputLabel>
          <TextField
            sx={textFieldSx}
            size="small"
            id="shortName"
            name="shortName"
            data-testid="short-name"
            fullWidth
            value={task.shortName || ''}
            placeholder="Ex. Please have vendor complete the Cloud Security Requirements Mat.."
            helperText="This name appears in the Task Assigner options on the Review tab of an SEP's Overview"
            onChange={(e) => handleChange(e)}
          />
        </Box>
        <Box>
          <InputLabel
            sx={{
              color: '#222222',
            }}
            htmlFor="task-assignment-name"
          >
            Task Description
          </InputLabel>
          <RichTextEditor
            style={{
              height: '120px',
            }}
            aria-label="task-description"
            value={description}
            setValue={setDescription}
            placeholder="Ex. We need to understand the scope to determine which teams will need to review this request. Please select all that apply."
          />
          <Typography
            sx={{
              margin: '5px 0px',
              color: '#646669',
              fontSize: '11px',
            }}
          >
            Keep it 2-3 sentences. Briefly describe why this screen is here and
            how it helps the process.
          </Typography>
        </Box>
        <Box sx={{ display: "flex", gap: 5 }}>
          <Box>
            <InputLabel
              sx={{
                color: "#222222",
              }}
            >
              Group Name
            </InputLabel>
            <TextField
              sx={textFieldSx}
              size="small"
              id="groupName"
              name="groupName"
              style={{ width: 250 }}
              data-testid="task-name"
              value={task?.groupName || ''} 
              onChange={(e) => handleChange(e)}
              placeholder="Enter task Group name"
              helperText="This will be the group name for this Task"
            />
          </Box>

          <Box>
            <InputLabel
              sx={{
                color: "#222222",
              }}
            >
              Group Number
            </InputLabel>
            <TextField
              sx={textFieldSx}
              size="small"
              id="groupId"
              name="groupId"
              style={{ width: 250 }}
              data-testid="task-id"
              value={task?.groupId || ''}
              placeholder="Enter task Group id"
              helperText="This will be the group id for this Task"
              onChange={(e) => handleChange(e)}
            />
          </Box>
        </Box>
        <Box>
        <InputLabel
              sx={{
                color: "#222222",
              }}
            >
              Task Age
            </InputLabel>
            <TextField
              sx={textFieldSx}
              size="small"
              id="taskAge"
              name="taskAge"
              style={{ width: 250 }}
              data-testid="task-id"
              value={task?.taskAge || ''}
              placeholder="Enter task task age"
              helperText="This will be the task age for this Task"
              onChange={(e) => handleChange(e)}
            />
        </Box>
      </Stack>
    </Box>
  );
};

export default FirstStep;
